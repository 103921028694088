const {
	VUE_APP_GOOGLE_VERSION,
	VUE_APP_GOOGLE_LANGUAGE
} = process.env
import helperApi from '@/api/helper'

const MC_ROLE_ID = 8

const { Loader } = require('google-maps')

const calcDurationHours = duration => Math.floor(duration / 3600)
const calcDurationMinutes = (duration, dHours) => Math.round((duration - dHours * 3600) / 60)

export default {
	namespaced: true,
	state: {
		loading: false,
		baseApiActive: false,
		googleInstance: null,
		googleInfoBoxInstance: null,
		geocoderInstance: null,
		directionServiceInstance: null,
		key: null
	},
	getters: {
		googleMapIsActive: (state) => state.baseApiActive,
		getGoogle: (state) => state.googleInstance,
		getGeocoder: (state) => state.geocoderInstance,
		getDirectionService: (state) => state.directionServiceInstance,
		userHasMCRole: (_s, _g, _rs, rootGetters) => rootGetters.getRoles.find(role => role.id === MC_ROLE_ID),
		getTypeOfApiKey: (state, getters) => getters.userHasMCRole ? 'mc' : 'user'
	},
	mutations: {
		SET_STATE_FIELD (state, payload) {
			state[payload.field] = payload.value;
		}
	},
	actions: {
		async manageApiKey ({getters, commit}) {
			try {
				const response = await helperApi.getGoogleApiKey(getters.getTypeOfApiKey)

				commit('SET_STATE_FIELD', {
					field: 'key',
					value: response.data.key
				})
			} catch (e) {
				commit('SET_STATE_FIELD', {
					field: 'key',
					value: null
				})
			}
		},
		/**
     * Create and load google loader instance
     * @returns {Promise<google> | boolean}
     */
		loadGoogle ({state}) {
			if (!state.key) {
				return false
			}

			const loader = new Loader(state.key, {
				version: VUE_APP_GOOGLE_VERSION,
				language: VUE_APP_GOOGLE_LANGUAGE,
				region: 'RU',
				libraries: ['places', 'drawing', 'geometry']
			})

			return loader.load()
		},
		async initGoogleMapApi ({ getters, dispatch, commit }) {
			console.log('initGoogleMapApi')
			if (getters.googleMapIsActive) {
				return true
			}

			commit('SET_STATE_FIELD', {
				field: 'loading',
				value: true
			})
			commit('SET_STATE_FIELD', {
				field: 'baseApiActive',
				value: true
			})

			await dispatch('manageApiKey')
			const google = await dispatch('loadGoogle')

			if (!google) {
				commit('SET_STATE_FIELD', {
					field: 'baseApiActive',
					value: false
				})
				commit('SET_STATE_FIELD', {
					field: 'loading',
					value: false
				})

				return false
			}

			commit('SET_STATE_FIELD', {
				field: 'googleInstance',
				value: google
			})
			commit('SET_STATE_FIELD', {
				field: 'geocoderInstance',
				value: new (getters.getGoogle).maps.Geocoder()
			})
			commit('SET_STATE_FIELD', {
				field: 'directionServiceInstance',
				value: new (getters.getGoogle).maps.DirectionsService()
			})
			commit('SET_STATE_FIELD', {
				field: 'baseApiActive',
				value: true
			})
			commit('SET_STATE_FIELD', {
				field: 'loading',
				value: false
			})

			return true
		},
		calcDistanceAndDurationForEachPoint (context, { route, durationCoef = 1 }) {
			if (!route) {
				return null;
			}
			if (route.length === 0) {
				return null;
			}
			if (!route[0].legs) {
				return null;
			}

			/**
       * Part of routes
       * @type {google.maps.DirectionsLeg[]}
       */
			const googleRouteLegs = route[0].legs;

			const distance = googleRouteLegs
				.map(leg => leg.distance.value)
				.reduce((a, b) => a + b, 0);

			const duration = googleRouteLegs
				.map(leg => leg.duration.value)
				.reduce((a, b) => a + b, 0) * durationCoef;

			const durationHours = calcDurationHours(duration);
			const durationMinutes = calcDurationMinutes(duration, durationHours);

			const points = [];

			googleRouteLegs.forEach((route_, index) => {
				const localDistance = googleRouteLegs[index].distance.value;
				const localDuration = googleRouteLegs[index].duration.value * durationCoef;

				const localDurationHours = calcDurationHours(localDuration);
				const localDurationMinutes = calcDurationMinutes(localDuration, localDurationHours);

				points.push({
					distance: localDistance / 1000,
					duration: {
						hours: localDurationHours,
						minutes: localDurationMinutes
					}
				})
			})

			return {
				distance: distance / 1000,
				duration: {
					hours: durationHours,
					minutes: durationMinutes
				},
				_points: points
			}
		}
	}
}
